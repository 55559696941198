body {
  background-color: #70c0de;
}

.App {
  height: 100%;
  width: 100%;
  background: black;
}

ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.name p {
  font-weight: bold;
  font-size: 46px;
  margin: 0;
}

.name {
  margin-bottom: 25px;
}

li {
  flex: 1 0 32%;
  min-height: 30vw;
  margin: 0;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}

@media only screen and (max-width: 1800px) {
  li {
    flex: 1 0 49%;
    min-height: 50vw;
  }
}

@media only screen and (max-width: 850px) {
  li {
    flex: 1 0 100%;
    margin: auto;
    min-height: 40vh;
  }
}

li:hover a {
  background-color: #222;
  color: #70c0de;
}

li:hover .overlay {
  opacity: 0.2;
}

li:hover p.tags {
  color: #70c0de;
}

li a {
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  position: relative;
}

li a span {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
}

li a span p.subtitle {
  text-transform: none;
  font-size: 24px;
}

li p.tags {
  text-transform: none;
  font-size: 18px;
  color: #9acfe3;
}

input {
  position: fixed;
  left: 50%;
  bottom: 22px;
  transform: translateX(-50%);
  padding: 18px;
  background: white;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 24px;
  transition: all 0.4s ease;
  opacity: 0.4;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  li {
    min-height: 80vh;
  }

  input {
    opacity: 0.7;
  }
}

input:hover,
input:focus {
  opacity: 1;
  padding: 22px;
  bottom: 20px;
}

.nothing {
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
}

.nothing p {
  position: absolute;
  color: white;
  left: 50%;
  top: 50%;
  font-size: 30px;
  transform: translate(-50%, -50%);
}
